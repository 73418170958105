import * as React from 'react';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import { ScheduleWeek } from '../../../domain/ScheduleWeek';
import ScheduleTableBlock from './ScheduleTableBlock';
import ScheduleTableBlockMobile from './mobile/ScheduleTableBlockMobile';
import { ScheduleView } from '../ScheduleContent';

interface Props {
    name: string;
    week?: ScheduleWeek;
    entries: ScheduleEntry[];
    assigned: number[];
    editable?: boolean;
    view: ScheduleView;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

export interface EntryObject {
    name: string;
    entries: ScheduleEntry[];
}

export default (props: Props) => {
    const [entries, setEntries] = React.useState<EntryObject[]>([]);

    React.useEffect(() => {
        const merged: EntryObject[] = [];
        props.entries.forEach(entry => {
            const same = merged.find(obj => obj.name === entry.name);
            if (same) {
                same.entries.push(entry);
            } else {
                const obj = { name: entry.name, entries: [] } as EntryObject;
                obj.entries.push(entry);
                merged.push(obj)
            }
        });
        setEntries(merged);
    }, [props.entries]);

    return props.view === "fit" ? <ScheduleTableBlockMobile {...props} entries={entries} /> : <ScheduleTableBlock {...props} entries={entries} />
    
};