import React from "react";

type WithLazyMountingProps = {
    isOpen: boolean;
};

export function withLazyMounting<T extends object>(
    WrappedComponent: React.ComponentType<T>
): React.FC<T & WithLazyMountingProps> {
    return (props: T & WithLazyMountingProps) => {
        const { isOpen, ...restProps } = props;
        if (isOpen) {
            return (
                <WrappedComponent {...(restProps as T)} isOpen={isOpen} />
            );
        } else return null;
    };
}