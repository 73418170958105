import { useStores } from '../../../stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Publisher } from '../../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import PublisherForm from '../PublisherForm';
import { useTranslation } from "react-i18next";
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const NewPublisherDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();

    const handleSubmit = (publisher: Publisher) => {
        publisherStore.createPublisher(publisher, () => {
            publisherStore.getAllPublishers();
            props.onClose();
        });
    }

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("new_publisher")}</DialogTitle>
            <DialogContent>
                <PublisherForm
                    onSubmit={handleSubmit}>
                    <DialogActions>
                        <Button onClick={props.onClose}>{t("cancel")}</Button>
                        <Button type='submit'>{t("submit")}</Button>
                    </DialogActions>
                </PublisherForm>
            </DialogContent>

        </Dialog>
    );
});

export default withLazyMounting(NewPublisherDialog);