import * as React from 'react';
import { Card, CardActions, CardContent, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { isMobileOnly } from 'react-device-detect';

export default observer(() => {
    const { localStorageStore } = useStores();
    const scheduleView = localStorageStore.getScheduleView(isMobileOnly);
    const [checked, setChecked] = React.useState<boolean>(scheduleView === 'fit');
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        localStorageStore.setScheduleView(isChecked ? 'fit' : 'default');
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div">
                    {t("new_schedule_view")}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {t("only_for_mobile")}
                </Typography>
            </CardContent>
            <CardActions>
                <FormGroup>
                    <FormControlLabel
                        checked={checked}
                        control={<Switch onChange={handleChange} />}
                        label={t(checked ? "new" : "old")} />
                </FormGroup>
            </CardActions>
        </Card>
    );
});