import * as React from 'react';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { ScheduleEntry } from '../../domain/ScheduleEntry';
import moment from 'moment';
import WeekSwitcher from '../../components/WeekSwitcher';
import { ScheduleWeek } from '../../domain/ScheduleWeek';
import SchedulePageTabs from './SchedulePageTabs';
import SchedulePageInfoBox from './SchedulePageInfoBox';
import ScheduleSkippedWeekTabs from './ScheduleSkippedWeekTabs';
import { isMobileOnly } from 'react-device-detect';

export type ScheduleView = "default" | "fit";

interface Props {
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { scheduleStore, localStorageStore } = useStores();
    const [week, setWeek] = React.useState<ScheduleWeek | undefined>();
    const [entries, setEntries] = React.useState<ScheduleEntry[]>([]);
    const [currentDate, setCurrentDate] = React.useState<Date>(moment().startOf("week").toDate());

    React.useEffect(() => {
        if (!scheduleStore.weeks.length) {
            scheduleStore.getWeek(currentDate, true);
        }
    }, []);

    React.useEffect(() => {
        if (scheduleStore.currentWeek) {
            setWeek(scheduleStore.currentWeek);
            scheduleStore.currentWeek.entries.sort((a, b) => a.position - b.position)
            setEntries(scheduleStore.currentWeek.entries);
        } else {
            setEntries([]);
        }
    }, [scheduleStore.currentWeek]);

    const handleDateUpdate = (date: Date) => {
        scheduleStore.getWeek(date);
        setCurrentDate(date);
    }

    const scheduleView = localStorageStore.getScheduleView(isMobileOnly);
    return (
        <>
            <WeekSwitcher date={currentDate} onDateUpdate={handleDateUpdate} />
            {week ? (
                <>
                    {week.skip ? (
                        <ScheduleSkippedWeekTabs
                            week={week}
                            entries={entries}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />
                    ) : (
                        <SchedulePageTabs
                            week={week}
                            entries={entries}
                            editable={props.editable}
                            view={scheduleView}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />
                    )}
                </>
            ) : (
                <SchedulePageInfoBox text={t("empty")} />
            )}
            <br /><br />
            <div style={{ marginLeft: "auto", marginRight: 10 }} >
                <WeekSwitcher date={currentDate} onDateUpdate={handleDateUpdate} />
            </div>

        </>
    );
});