import * as React from 'react';
import { useStores } from '../../../stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from "react-i18next";
import { LiteratureRequest } from '../../../domain/LiteratureRequest';
import { LiteratureType } from '../../../domain/LiteratureType';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    types: LiteratureType[];
    requests: LiteratureRequest[];
    publisher?: Publisher;
    isOpen: boolean;
    onClose: () => void;
}

const UpdateLiteratureRequestDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { literatureStore } = useStores();
    const [publisherRequests, setPublisherRequests] = React.useState<LiteratureRequest[]>([]);

    React.useEffect(() => {
        const initRequests = props.types.map(type => {
            if (props.publisher) {
                const request = props.requests.find(req => req.publisherId === props.publisher!.id && req.literatureTypeId === type.id);
                return request ? { ...request } : { literatureTypeId: type.id, number: 0 } as LiteratureRequest;
            } else return { literatureTypeId: type.id, number: 0 } as LiteratureRequest;
        });        
        setPublisherRequests(initRequests);
    }, [props.types.length, props.publisher]);

    const handleChange = (value: any, type: LiteratureType) => {
        const reqs = publisherRequests.map(req => {
            const newReq = { ...req };
            if (req.literatureTypeId === type.id) {
                newReq.number = Number(value);
            }
            return newReq;
        });
        setPublisherRequests(reqs);
    }

    const saveRequests = () => {
        const processed = publisherRequests.map(req => ({
            ...req,
            publisherId: props.publisher!.id
        }));
        literatureStore.saveBatch(processed);
        props.onClose();
    }


    return (
        <Dialog open={props.isOpen} fullWidth={true} onClose={props.onClose}>
            <DialogTitle>{t("edit_literature_requests")}</DialogTitle>
            <DialogContent>

                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {props.types && publisherRequests.length > 0 && props.types
                                .map((type: LiteratureType) => {
                                    const request = publisherRequests.find(req => req.literatureTypeId === type.id)!;
                                    return (
                                        <TableRow key={type.id}>
                                            <TableCell>{type.name}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    margin="normal"
                                                    id={type.id + ""}
                                                    label={t("count")}
                                                    type="number"
                                                    variant="standard"
                                                    value={request && request.number}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    onChange={(e: any) => handleChange(e.target.value, type)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )

                                })}
                        </TableBody>
                    </Table>
                </TableContainer>


            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("cancel")}</Button>
                <Button color="primary" onClick={saveRequests}>{t("submit")}</Button>
            </DialogActions>
        </Dialog>
    );
});

export default withLazyMounting(UpdateLiteratureRequestDialog);