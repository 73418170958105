import * as React from 'react';
import { Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CachedIcon from '@mui/icons-material/Cached';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import moment from 'moment';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';
import { Territory } from '../../../domain/Territory';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import AssignTerritoryDialog from '../dialogs/AssignTerritoryDialog';
import ShowTerritoryDialog from '../dialogs/ShowTerritoryDialog';
import EditAssignmentDialog from '../dialogs/EditAssignmentDialog';
import Can from '../../../components/Can';
import { isMobile } from 'react-device-detect';
import { useConfirm } from 'material-ui-confirm';

const PAGE_SIZE = 10;

export default observer(() => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const { territoryStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [page, setPage] = React.useState<number>(1);
    const [history, setHistory] = React.useState<TerritoryAssignment[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | undefined>();

    React.useEffect(() => {
        territoryStore.getHistory(page, PAGE_SIZE, (data) => {
            setHistory(data);
        })
    }, [page]);

    const doRefresh = () => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("do_you_want_to_fetch_assignment_data_from_th")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            territoryStore.refreshTerritoryAssignments(3);
        });
        
    }

    const handleChangePage = (_: any, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleAssignmentChange = () => {
        territoryStore.getHistory(page, PAGE_SIZE, (data) => {
            setHistory(data);
        })
    }

    const handleAssignmentDeletion = () => {
        territoryStore.getHistory(page, PAGE_SIZE, (data) => {
            setHistory(data);
        })
    }

    const openTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("show-territory");
    }

    const openAssignmentDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("assign-territory");
    }

    const openEditAssignmentDialog = (assignment: TerritoryAssignment) => {
        setSelectedAssignment(assignment);
        openDialog("edit-assignment");
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
        setSelectedAssignment(undefined);
    }

    return (
        <>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <TableContainer component={Paper}>
                    <Toolbar>
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="h5"
                        >
                            {t("history_of_assignments")}
                        </Typography>
                        {!isMobile && <Can do="manage" on='Admin'>
                            <IconButton onClick={doRefresh}>
                                <CachedIcon />
                            </IconButton>
                        </Can>}
                    </Toolbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("group")}</TableCell>
                                <TableCell>{t("title")}</TableCell>
                                <TableCell>{t("publisher")}</TableCell>
                                <TableCell>{t("notes")}</TableCell>
                                <TableCell>{t("taken_at")}</TableCell>
                                <TableCell>{t("returned_at")}</TableCell>
                                <TableCell>{t("days_gone")}</TableCell>
                                <TableCell>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map(assignment => (
                                <TableRow key={assignment.id}>
                                    <TableCell>{assignment.territory!.territoryGroup!.name}</TableCell>
                                    <TableCell style={{ maxWidth: "350px" }}><b>{assignment.territory!.title} </b></TableCell>
                                    <TableCell>{assignment.publisher ? `${assignment.publisher!.lastName} ${assignment.publisher!.firstName}` : t("someone")}</TableCell>
                                    <TableCell style={{ maxWidth: "200px" }}>{assignment.notes}</TableCell>
                                    <TableCell>{moment(assignment.takenAt).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{moment(assignment.returnedAt).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell><b>{moment(assignment.returnedAt).diff(moment(assignment.takenAt), 'days')}</b></TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => openTerritoryDialog(assignment.territory!)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton onClick={() => openAssignmentDialog(assignment.territory!)}>
                                            <AssignmentIndIcon />
                                        </IconButton>
                                        <IconButton onClick={() => openEditAssignmentDialog(assignment)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={-1}
                    rowsPerPageOptions={[]}
                    rowsPerPage={PAGE_SIZE}
                    page={page - 1}
                    onPageChange={handleChangePage}
                />
            </Container>
            <ShowTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("show-territory")}
                onClose={handleCloseDialog}
            />
            <AssignTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("assign-territory")}
                onAssignmentEdit={openEditAssignmentDialog}
                onClose={handleCloseDialog}
            />
            <EditAssignmentDialog
                assignment={selectedAssignment!}
                isOpen={isOpen("edit-assignment")}
                onClose={handleCloseDialog}
                onAssignmentChange={handleAssignmentChange}
                onAssignmentDeletion={handleAssignmentDeletion}
            />
        </>

    );
});