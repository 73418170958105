import * as React from 'react';
import { useStores } from '../../../stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { LiteratureType } from '../../../domain/LiteratureType';
import { useConfirm } from 'material-ui-confirm';
import EditLiteratureTypeDialog from './EditLiteratureTypeDialog';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const ManageLiteratureTypesDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const { literatureStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [newTypeName, setNewTypeName] = React.useState<string>("");
    const [selectedType, setSelectedType] = React.useState<LiteratureType>();

    const onNewTypeNameChange = (e: any) => {
        setNewTypeName(e.target.value);
    }

    const confirmNewType = () => {
        literatureStore.saveLiteratureType({ name: newTypeName });
        setNewTypeName("");
    }

    const deleteType = (id: number) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            literatureStore.deleteLiteratureType(id);
        });
    }

    const openEditDialog = (type: LiteratureType) => {
        setSelectedType(type);
        openDialog("edit-literature-type")
    }

    const handleCloseDialog = () => {
        setSelectedType(undefined);
        closeDialog();
    }

    return (
        <Dialog open={props.isOpen} fullWidth={true} onClose={props.onClose}>
            <DialogTitle>{t("manage_literature")}</DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t("name")}</TableCell>
                                <TableCell>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {literatureStore.literatureTypes && literatureStore.literatureTypes
                                .map((type: LiteratureType, i: number) => (
                                    <TableRow key={type.id}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{type.name}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => openEditDialog(type)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => deleteType(type.id)} >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell />
                                <TableCell>
                                    <TextField
                                        margin="normal"
                                        id="name"
                                        label={t("name")}
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={newTypeName}
                                        onChange={onNewTypeNameChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={confirmNewType} >
                                        <CheckIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("cancel")}</Button>
            </DialogActions>
            
            <EditLiteratureTypeDialog
                type={selectedType}
                isOpen={isOpen("edit-literature-type")}
                onClose={handleCloseDialog}
            />
        </Dialog>
    );
});

export default withLazyMounting(ManageLiteratureTypesDialog);