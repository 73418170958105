import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Autocomplete } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { AccountingEntry } from '../../../domain/AccountingEntry';
import * as yup from 'yup';
import { getLocale } from '../../../utils/localeUtils';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    date: Date;
    onSubmit: (entries: AccountingEntry[]) => void;
    onClose: () => void;
}

const AccountingBoxEntryDialog = observer((props: Props) => {
    const { t } = useTranslation();

    const counterpartyOptions = process.env.REACT_APP_ACCOUNTING_COUNTERPARTY_OPTIONS!.split(",");

    let formData = {
        date: moment(props.date).format('YYYY-MM-DD'),
        counterparty: counterpartyOptions[0]
    } as any;

    const schema = yup.object().shape({
        date: yup.date().required(t("field_is_required", { name: t("date") })),
        document: yup.string().required(t("field_is_required", { name: t("document") })),
        counterparty: yup.string().required(t("field_is_required", { name: t("counterparty") })),
        amount1: yup.number(),
        amount2: yup.number(),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: formData,
        onSubmit: (entry, { resetForm }) => {

            const entry1 = {
                date: moment(entry.date).utcOffset(0, true).toDate(),
                document: entry.document,
                counterparty: entry.counterparty,
                amount: entry.amount1 || 0,
                description: "Ziedojums pasaules meroga darbam",
                code: "PM",
                type: "cash",
                side: "income"
            } as AccountingEntry;

            const entry2 = {
                date: moment(entry.date).utcOffset(0, true).toDate(),
                document: entry.document,
                counterparty: entry.counterparty,
                amount: entry.amount2 || 0,
                description: "Ziedojums draudzes izdevumiem",
                code: "Z",
                type: "cash",
                side: "income"
            } as AccountingEntry;

            props.onSubmit([entry1, entry2]);
            resetForm();
            props.onClose();
        }
    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("contribution_boxes")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            maxDate={new Date()}
                            value={formik.values.date}
                            onChange={value => {
                                formik.setFieldValue('date', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!formik.errors.date}
                                    helperText={formik.errors.date && formik.errors.date as string}
                                    margin="normal"
                                    name="date"
                                />}
                        />
                    </LocalizationProvider>
                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                error={!!formik.errors.document}
                                margin="normal"
                                id="document"
                                label={t("document")}
                                fullWidth
                                variant="standard"
                                helperText={formik.errors.document && formik.errors.document as string}
                                value={formik.values.document || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                disabled={true}
                                margin="normal"
                                id="counterparty"
                                label={t("counterparty")}
                                variant="standard"
                                value={formik.values.counterparty || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                margin="normal"
                                id="amount1"
                                label={t("worldwide")}
                                type="number"
                                variant="standard"
                                value={formik.values.amount1 || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                margin="normal"
                                id="amount2"
                                label={t("congregation_expenses")}
                                type="number"
                                variant="standard"
                                value={formik.values.amount2 || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(AccountingBoxEntryDialog);