import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { withLazyMounting } from '../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    reports: FieldServiceReport[];
    onClose: () => void;
}

const PivotChartDialog = (props: Props) => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState<any>([]);

    React.useEffect(() => {
        const newChartData = props.reports && props.reports.map(report => ({
            name: moment(report.date).format("YYYY-MM"),
            hours: report.hours,
        })).reverse();
        setChartData(newChartData);
    }, [props.reports])

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="lg">
            <DialogTitle>{t("pivot_reports")}</DialogTitle>
            <DialogContent >
                <LineChart 
                    height={400}
                    width={700}
                    data={chartData}
                    margin={{
                        top: 5,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="name" interval={4} />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey="hours" name={t("hours")} stroke="#8884d8" />
                </LineChart >
            </DialogContent>
        </Dialog>
    );
};

export default withLazyMounting(PivotChartDialog);