import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';

export class WebPushStore {
    rootStore: RootStore;
    serverStore: ServerStore;

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    registerServiceWorker = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(() => {
                    console.log('Service Worker registered');
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        }
    }

    requestNotificationPermission = async (): Promise<boolean> => {
        if ('Notification' in window) {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                return true;
            } else if (permission === 'denied') {
                return false;
            }
        }
        return false;
    }

    subscribeUser = async () => {
        const vapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
        if (vapidKey) {
            const permissionGranted = await this.requestNotificationPermission();
            if (permissionGranted) {
                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array(vapidKey),
                });
        
                this.serverStore.post('/push/subscribe', JSON.stringify(subscription), action(() => {
                    console.log('User is subscribed');
                }));
            }
        }
    }

    unsubscribe = () => {
        this.serverStore.post('/push/unsubscribe', undefined, action(() => {
            console.log('User is unsubscribed');
        }));
    }

    urlBase64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

}