import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useStores } from '../../stores';
import { AppAbility } from '../../permissions/ability';

interface Props {
    ability: AppAbility;
}

export default observer((props: Props) => {
    const location = useLocation();
    const { userStore } = useStores();
    const isAuth = userStore.userData.loggedIn;

    const contains = (paths: string[]) => {
        return paths.some(p => location.pathname.includes(p));
    }

    if (!isAuth) {
        return <Navigate to='/login' replace state={{ from: location }} />
    } else {
        let hasRole = false;
        if (contains(["home", "login", "events", "my-reports", "my-territories", "alerts", "settings"])) {
            hasRole = true;
        } else {
            if (contains(["publishers"])) {
                hasRole = props.ability.can('read', 'Publisher');
            } else if (contains(["carts"])) {
                hasRole = props.ability.can('read', 'Cart');
            } else if (contains(["groups"])) {
                hasRole = props.ability.can('read', 'Groups');
            } else if (contains(["accounting"])) {
                hasRole = props.ability.can('read', 'Accounting');
            } else if (contains(["schedule"])) {
                hasRole = props.ability.can('read', 'ScheduleEntry');
            } else if (contains(["attendance", "pivot-attendance"])) {
                hasRole = props.ability.can('read', 'Attendance');
            } else if (contains(["literature"])) {
                hasRole = props.ability.can('read', 'Literature');
            } else if (contains(["users"])) {
                hasRole = props.ability.can('read', 'User');
            } else if (contains(["group-reports"])) {
                hasRole = props.ability.can('read', 'GroupReports');
            } else if (contains(["overall-report", "pivot-reports"])) {
                hasRole = props.ability.can('read', 'OverallReport');
            } else if (contains(["overall-report", "pivot-reports"])) {
                hasRole = props.ability.can('read', 'OverallReport');
            } else if (contains(["activity"])) {
                hasRole = props.ability.can('read', 'Activity');
            } else if (contains(["knowledge"])) {
                hasRole = props.ability.can('read', 'Knowledge');
            } else if (contains(["territory"])) {
                hasRole = props.ability.can('read', 'Territory');
            } else if (contains(["files"])) {
                hasRole = props.ability.can('read', 'Files');
            }
        }

        if (hasRole) {
            return <Outlet />
        }
    }
    
    return <Navigate to='/home' replace state={{ from: location }} />
});