import * as React from 'react';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { getLocale } from '../../../utils/localeUtils';
import { useStores } from '../../../stores';
import * as yup from 'yup';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    date: Date;
}

const AccountingS26Dialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { accountingStore } = useStores();

    const schema = yup.object().shape({
        transferToBranchDate: yup.date(),
        fromBox: yup.number().required(t("field_is_required", { name: t("from_box") })),
        monthlyResolution: yup.number().required(t("field_is_required", { name: t("monthly_resolution") })),
        exceedResolution: yup.number(),
    });

    const initialValues = {
        transferToBranchDate: moment().toDate(),
        fromBox: 0,
        monthlyResolution: 0,
        exceedResolution: 0
    }

    const formik = useFormik({
        validationSchema: schema,
        initialValues,
        onSubmit: (values) => {
            accountingStore.downloadS26({ date: props.date, ...values });
            props.onClose();
        },

    });

    React.useEffect(() => {
        if (props.isOpen) {
            accountingStore.getSummary(moment(props.date).format("YYYY-MM"), "month", (summary) => {
                formik.setFieldValue("fromBox", summary.incomes["На всемирное дело"])
                formik.setFieldValue("monthlyResolution", summary.expenses["Ежемесячное пожертвование по рез."])
            });
        }
    }, [props.isOpen]);

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_s_26")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("transfer_to_branch_date")}
                            maxDate={new Date()}
                            value={formik.values.transferToBranchDate}
                            onChange={value => {
                                formik.setFieldValue('transferToBranchDate', value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="date" />}
                        />
                    </LocalizationProvider>

                    <Grid container>
                        <Grid item xs={4} padding={1}>
                            <TextField
                                margin="normal"
                                id="fromBox"
                                label={t("from_box")}
                                type="number"
                                variant="standard"
                                value={formik.values.fromBox}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4} padding={1}>
                            <TextField
                                margin="normal"
                                id="monthlyResolution"
                                label={t("monthly_resolution")}
                                type="number"
                                variant="standard"
                                value={formik.values.monthlyResolution}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4} padding={1}>
                            <TextField
                                margin="normal"
                                id="exceedResolution"
                                label={t("exceed_resolution")}
                                type="number"
                                variant="standard"
                                value={formik.values.exceedResolution}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(AccountingS26Dialog);