import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Publisher } from '../../../domain/Publisher';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { getLocale } from '../../../utils/localeUtils';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    publisher: Publisher;
    onSubmit: (publisher: Publisher) => void;
    onClose: () => void;
}

const EditDeletedPublisherDialog = (props: Props) => {
    const { t } = useTranslation();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: props.publisher,
        onSubmit: (publisher) => {
            props.onSubmit(publisher)
        }
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("publisher")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            maxDate={new Date()}
                            value={values.deletedAt}
                            onChange={value => {
                                formik.setFieldValue('deletedAt', value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    margin="normal"
                                    name="date"
                                />}
                        />
                    </LocalizationProvider>
                    <TextField
                        margin="normal"
                        id="lastName"
                        label={t("surname")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={values.lastName || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="notes"
                        label={t("notes")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={values.notes || ''}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default withLazyMounting(EditDeletedPublisherDialog);