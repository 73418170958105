import * as React from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";
import { ActivityRecord } from '../../domain/ActivityRecord';
import moment from 'moment';

interface Props {
    records: ActivityRecord[];
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [openNotes, setOpenNotes] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = React.useState<ActivityRecord | undefined>();

    const handleClick = (record: ActivityRecord) => {
        if (record.data) {
            setSelectedRecord(record)
            setOpenNotes(true);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("user")}</TableCell>
                                <TableCell>{t("records")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.records
                                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                                .map((record: ActivityRecord) => (
                                    <TableRow key={record.date + ""}>
                                        <TableCell>{moment(record.date).format("HH:mm")}</TableCell>
                                        <TableCell><b>{record.user}</b></TableCell>
                                        <TableCell>
                                            <Stack alignItems="center" direction="row" gap={1}>
                                                <Typography variant="body1">{record.record}</Typography>
                                                {record.data && <InfoOutlinedIcon onClick={() => handleClick(record)} />}
                                            </Stack>
                                            
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Dialog open={openNotes} onClose={() => setOpenNotes(false)} >
                <DialogTitle>{t("notes")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography component="pre">
                            {selectedRecord?.data && JSON.stringify(selectedRecord!.data, null, 2)}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenNotes(false)}>{t("close")}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};