import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import PublisherScheduleDialog from '../../publishers/dialogs/PublisherScheduleDialog';

interface Props {
    assignmentId?: number;
    entry?: ScheduleEntry;
    isOpen: boolean;
    onClose: () => void;
}

const SchedulePublisherHistoryDialog = (props: Props) =>
    <PublisherScheduleDialog
        isOpen={props.isOpen}
        publisherId={props.entry && props.entry.speakerId!}
        onClose={props.onClose}
    />;

export default SchedulePublisherHistoryDialog;