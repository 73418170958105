import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TextareaAutosize, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import { useStores } from '../../../stores';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onSubmit: (entry: ScheduleEntry) => void;
    onClose: () => void;
}

const ScheduleEntryCreateDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { appointmentStore } = useStores();

    const schema = yup.object().shape({
        name: yup.string().required(t("field_is_required", { name: t("name") })),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: {
            participants: "all",
            type: "workday",
            subType: "intro",
            position: 1,
            room: 1,
            companionNeeded: false
        } as ScheduleEntry,
        onSubmit: (entry, { resetForm }) => {
            props.onSubmit(entry);
            resetForm();
            props.onClose();
        }
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("schedule_entry")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={8} padding={1}>
                            <TextField
                                margin="normal"
                                id="name"
                                label={t("name")}
                                fullWidth
                                variant="standard"
                                value={values.name || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>

                        <Grid item xs={2} padding={1}>
                            <TextField
                                margin="normal"
                                id="position"
                                label={t("position")}
                                type="number"
                                fullWidth
                                variant="standard"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={values.position || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={2} padding={1}>
                            <TextField
                                margin="normal"
                                id="room"
                                label={t("room")}
                                type="number"
                                fullWidth
                                variant="standard"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={values.room || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel>{t("participants")}</InputLabel>
                                <Select
                                    id="participants"
                                    value={values.participants}
                                    label={t("participants")}
                                    onChange={(option) => {
                                        formik.setFieldValue("participants", option.target.value);
                                    }}
                                >
                                    {["elders", "appointed", "brothers", "sisters", "all"]
                                        .map(assign => <MenuItem key={assign} value={assign}>{t(assign)}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel>{t("assignment")}</InputLabel>
                                <Select
                                    id="assignment"
                                    value={values.assignment ? values.assignment.id : ''}
                                    label={t("assignment")}
                                    onChange={(option: any) => {
                                        const assignment = appointmentStore.assignments.find(a => a.id === option.target.value);
                                        formik.setFieldValue("assignmentId", assignment?.id)
                                        formik.setFieldValue("assignment", assignment)
                                    }}
                                >
                                    {appointmentStore.assignments.map(assign => <MenuItem key={assign.id} value={assign.id}>{t(assign.name)}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>


                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel>{t("type")}</InputLabel>
                                <Select
                                    id="type"
                                    value={values.type}
                                    label={t("type")}
                                    onChange={(option) => {
                                        formik.setFieldValue("type", option.target.value);
                                    }}
                                >
                                    {["workday", "weekend", "field-service"]
                                        .map(type => <MenuItem key={type} value={type}>{t(type)}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel>{t("sub-type")}</InputLabel>
                                <Select
                                    id="subType"
                                    value={values.subType}
                                    label={t("sub-type")}
                                    onChange={(option) => {
                                        formik.setFieldValue("subType", option.target.value);
                                    }}
                                >
                                    {["intro", "treasures", "apply-yourself", "living-as-christians", "speech", "watchtower", "assignment"]
                                        .map(type => <MenuItem key={type} value={type}>{t(type)}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="companionNeeded"
                                    checked={values.companionNeeded}
                                    onChange={(event) => formik.setFieldValue("companionNeeded", event.target.checked)}
                                />
                            }
                            label={t("companion")}
                        />
                    </FormGroup>

                    <TextareaAutosize
                        id="notes"
                        value={values.notes || ''}
                        minRows={6}
                        placeholder={t("notes")}
                        style={{ width: "100%" }}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(ScheduleEntryCreateDialog);