import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    fileName: string;
    onClose: () => void;
}

interface FormValues {
    newFileName: string;
}

const FileRenameDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { filesStore } = useStores();

    const initialValues = {
        newFileName: '',
    } as FormValues;
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            filesStore.renameFile({ oldName: props.fileName, newName: values.newFileName });
            props.onClose();
        },
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("rename_file")}</DialogTitle>
                <DialogContent>

                    <TextField
                        margin="normal"
                        id="name"
                        fullWidth
                        variant="standard"
                        value={props.fileName}
                        disabled={true}
                    />
                    <TextField
                        margin="normal"
                        id="newFileName"
                        label={t("new_file_name")}
                        fullWidth
                        variant="standard"
                        value={values.newFileName || ''}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(FileRenameDialog);