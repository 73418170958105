import * as React from 'react';
import { Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getLocale } from '../../../utils/localeUtils';


export default observer(() => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();
    const [from, setFrom] = React.useState<Date>(moment().startOf('month').subtract(12, 'month').toDate());
    const [to, setTo] = React.useState<Date>(moment().startOf('month').toDate());
    const [data, setData] = React.useState<any>(undefined);

    React.useEffect(() => {
        territoryStore.getStatistics(from, to, (data) => {
            setData(data);
        })
    }, [from, to]);

    return (
        <>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                                <DatePicker
                                    label={t("from")}
                                    value={from}
                                    onChange={value => {
                                        setFrom(value!);
                                    }}
                                    renderInput={(params) => <TextField {...params} margin="normal" name="from" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                                <DatePicker
                                    label={t("to")}
                                    value={to}
                                    onChange={value => {
                                        setTo(value!);
                                    }}
                                    renderInput={(params) => <TextField {...params} margin="normal" name="to" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t("total_territories")}</TableCell>
                                <TableCell>{data && data.total}</TableCell>
                            </TableRow>
                            <TableRow><TableCell /><TableCell /></TableRow>
                            <TableRow>
                                <TableCell>{t("assigned_territories_at_range", { type: t("all") })}</TableCell>
                                <TableCell>{data && data.assignedAll}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("worked_territories_at_range", { type: t("all") })}</TableCell>
                                <TableCell>{data && data.workedAll}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("assigned_and_worked_territories_at_range", { type: t("all") })}</TableCell>
                                <TableCell>{data && data.assignedAndWorkedAll}</TableCell>
                            </TableRow>
                            <TableRow><TableCell /><TableCell /></TableRow>
                            <TableRow>
                                <TableCell>{t("assigned_territories_at_range", { type: t("congregation") })}</TableCell>
                                <TableCell>{data && data.assignedCongregation}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("worked_territories_at_range", { type: t("congregation") })}</TableCell>
                                <TableCell>{data && data.workedCongregation}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("assigned_and_worked_territories_at_range", { type: t("congregation") })}</TableCell>
                                <TableCell>{data && data.assignedAndWorkedCongregation}</TableCell>
                            </TableRow>
                            <TableRow><TableCell /><TableCell /></TableRow>
                            <TableRow>
                                <TableCell>{t("currently_assigned_territories")}</TableCell>
                                <TableCell>{data && data.currentlyAssigned}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>

    );
});