import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';

export default observer(() => {
    const { webPushStore, notificationsStore } = useStores();
    const { t } = useTranslation();

    const handleClick = async () => {
        const permissionGranted = await webPushStore.requestNotificationPermission();
        if (permissionGranted) {
            await webPushStore.subscribeUser();
            notificationsStore.add(t("push_notifications_enabled"), 'success');
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div">
                    {t("show_push_notifications")}
                </Typography>
            </CardContent>
            <CardActions>
                <Button variant='contained' color='success' onClick={handleClick}>{t("enable")}</Button>
            </CardActions>
        </Card>
    );
});