import * as React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { AccountingSummary } from '../../../domain/AccountingSummary';
import AccountingSummaryTable from '../AccountingSummaryTable';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    date: Date;
    onClose: () => void;
}

const AccountingSummaryDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { accountingStore } = useStores();
    const [summary, setSummary] = React.useState<AccountingSummary>();

    React.useEffect(() => {
        if (props.isOpen) {
            accountingStore.getSummary(moment(props.date).format("YYYY-MM"), "month", (summary) => {
                setSummary(summary);
            });
        }
    }, [props.isOpen]);

    return (
        <Dialog fullWidth maxWidth="md" open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("summary")}</DialogTitle>
            <AccountingSummaryTable summary={summary} />
        </Dialog>
    );
});

export default withLazyMounting(AccountingSummaryDialog);