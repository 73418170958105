import { useFormik } from 'formik';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import * as yup from 'yup';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    date: Date;
}

const AccountingS30Dialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { accountingStore } = useStores();

    const schema = yup.object().shape({
        flatten: yup.boolean(),
        locale: yup.string(),
    });

    const initialValues = {
        flatten: false,
        locale: "lv"
    }

    const formik = useFormik({
        validationSchema: schema,
        initialValues,
        onSubmit: (values) => {
            accountingStore.downloadS30({
                date: props.date,
                flatten: values.flatten,
                locale: values.locale
            });
            props.onClose();
        },

    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_s_30")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <FormControl sx={{ m: 1, minWidth: 140 }}>
                        <InputLabel>{t("who_has_access")}</InputLabel>
                        <Select
                            value={formik.values.locale}
                            label={t("who_has_access")}
                            onChange={(event: SelectChangeEvent) => {
                                formik.setFieldValue('locale', event.target.value as string);
                            }}
                        >
                            <MenuItem value="lv">{t("latvian")}</MenuItem>
                            <MenuItem value="ru">{t("russian")}</MenuItem>
                        </Select>
                    </FormControl>

                    <Grid container>
                        <Grid item xs={4} padding={1}>
                            <FormGroup
                                onChange={formik.handleChange} >
                                <FormControlLabel
                                    control={<Checkbox id="flatten" checked={formik.values.flatten} />}
                                    label={t("should_be_flatten")} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(AccountingS30Dialog);