import { useFormik } from 'formik';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { CartWitnessingRecord } from '../../../domain/CartWitnessingRecord';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useStores } from '../../../stores';
import { Publisher } from '../../../domain/Publisher';
import { isString } from 'lodash';
import Can from '../../../components/Can';
import { getLocale } from '../../../utils/localeUtils';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    from?: Date;
    to?: Date;
    cartRecord?: CartWitnessingRecord;
    onSubmit: (cartRecord: any) => void;
    onClose: () => void;
    onDelete?: (cartRecord: CartWitnessingRecord) => void;
    onCompanionAdd?: (from: Date, to: Date) => void;
}

const CartWitnessingRecordDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();

    let initialValues;
    if (props.cartRecord) {
        const publisher = props.cartRecord.customPublisher ?
            props.cartRecord.customPublisher :
            publisherStore.publishers.find(publ => publ.id === props.cartRecord?.publisherId)
        initialValues = {
            id: props.cartRecord.id,
            from: moment(props.cartRecord.from).toDate(),
            to: moment(props.cartRecord.to).toDate(),
            publisher,
            locked: props.cartRecord.locked
        }
    } else {
        initialValues = {
            from: props.from,
            to: props.to,
            publisher: publisherStore.myPublisher,
            locked: false
        };
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: (cartRecord) => {
            props.onSubmit(cartRecord);
            props.onClose();
        }
    });

    const handleCompanionAdd = () => {
        if (props.cartRecord && props.onCompanionAdd) {
            props.onClose();
            props.onCompanionAdd(props.cartRecord.from, props.cartRecord.to);
        }
    }

    const publishers = publisherStore.publishers
        .filter(publisher => !!publisher.assignments.find(a => a.id === 6));

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("cart_witnessing")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <MobileDateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label={t("from")}
                            openTo="hours"
                            minDate={new Date()}
                            value={formik.values.from}
                            onChange={value => {
                                formik.setFieldValue('from', value);
                            }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <MobileDateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label={t("to")}
                            openTo="hours"
                            minDate={new Date()}
                            value={formik.values.to}
                            onChange={value => {
                                formik.setFieldValue('to', value);
                            }}
                        />
                    </LocalizationProvider>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={11}>
                            <Autocomplete
                                freeSolo
                                disablePortal={false}
                                id="publisher"
                                options={publishers}
                                getOptionLabel={(label: Publisher | string) => {
                                    if (isString(label)) {
                                        return label as string;
                                    } else {
                                        const publisher = label as Publisher;
                                        return publisher.firstName ? `${publisher.lastName} ${publisher.firstName}` : ''
                                    }
                                }}
                                value={formik.values.publisher || ''}
                                isOptionEqualToValue={(option, value) => {
                                    if (isString(option)) {
                                        return option === value;
                                    } else {
                                        return option.id === (value as any).id;
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} margin="normal" label={t("publisher")} />}
                                onChange={(e, publisher) => {
                                    if (isString(publisher)) {
                                        formik.setFieldValue("customPublisher", publisher);
                                        formik.setFieldValue("publisher", undefined);
                                    } else {
                                        formik.setFieldValue("publisher", publisher);
                                        formik.setFieldValue("customPublisher", undefined);
                                    }

                                }}
                            />
                        </Grid>
                        <Grid  item xs={1}>
                            <IconButton onClick={() => formik.setFieldValue("locked", !formik.values.locked)}>
                                {formik.values.locked ? <LockIcon /> : <LockOpenIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>

                    {(props.cartRecord && !formik.values.locked) && (
                        <Can do="create" on='CartWitnessingRecord'>
                            <Button onClick={handleCompanionAdd}>{t("add_companion")}</Button>
                        </Can>
                    )}
                </DialogContent>
                <DialogActions>
                    {props.cartRecord && <Button color="error" onClick={() => props.onDelete!(props.cartRecord!)}>{t("delete")}</Button>}
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(CartWitnessingRecordDialog);