import { makeAutoObservable } from 'mobx';
import { ScheduleView } from '../modules/schedule/ScheduleContent';

export class LocalStorageStore {

    constructor() {
        makeAutoObservable(this);
    }

    setHomePageTab(tab: number) {
        localStorage.setItem('settings_home_tab', `${tab}`);
    }

    getHomePageTab(): number {
        const value = localStorage.getItem('settings_home_tab');
        if (value) {
            return parseInt(value);
        } else return 0;
    }

    setScheduleView(view: ScheduleView) {
        localStorage.setItem('settings_schedule_view', view);
    }

    getScheduleView(mobile: boolean): ScheduleView {
        if (mobile) {
            const view = localStorage.getItem('settings_schedule_view');
            if (view) {
                return view as ScheduleView;
            }
        }
        return "default";
    }

    clearAll() {
        localStorage.clear();
    }

}