import { useFormik } from 'formik';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getLocale } from '../../utils/localeUtils';
import { CongregationEvent } from '../../domain/CongregationEvent';
import * as yup from 'yup';
import Can from '../../components/Can';
import { Publisher } from '../../domain/Publisher';
import { withLazyMounting } from '../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    from?: Date;
    to?: Date;
    event?: CongregationEvent;
    publishers?: Publisher[];
    onSubmit: (cartRecord: any) => void;
    onClose: () => void;
    onDelete?: (cartRecord: CongregationEvent) => void;
}

const CongregationEventDialog = (props: Props) => {
    const { t } = useTranslation();

    let initialValues;
    if (props.event) {
        initialValues = {
            id: props.event.id,
            title: props.event.title,
            description: props.event.description,
            viewer: props.event.viewer,
            from: moment(props.event.from).toDate(),
            to: moment(props.event.to).toDate(),
            notification: props.event.notification ? moment(props.event.notification).toDate() : null,
        } as any
        if (props.event.publisherId) {
            initialValues.publisherId = props.event.publisherId;
            initialValues.publisher = props.publishers!.find(publ => publ.id === props.event?.publisherId);
        }
    } else {
        initialValues = {
            title: "",
            description: "",
            viewer: "all",
            from: props.from,
            to: props.to,
            notification: null,
            publisher: undefined
        };
    }

    const schema = yup.object().shape({
        title: yup.string().required(t("field_is_required", { name: t("title") }))
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues,
        onSubmit: (eventRecord) => {
            props.onSubmit(eventRecord);
            props.onClose();
        }
    });

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("congregation_event")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>

                    <TextField
                        margin="normal"
                        id="title"
                        label={t("title")}
                        fullWidth
                        variant="standard"
                        error={!!errors.title}
                        helperText={errors.title && errors.title as string}
                        value={values.title || ''}
                        onChange={formik.handleChange}
                    />

                    <TextField
                        margin="normal"
                        id="description"
                        label={t("description")}
                        fullWidth
                        variant="standard"
                        error={!!errors.description}
                        helperText={errors.description && errors.description as string}
                        value={values.description || ''}
                        onChange={formik.handleChange}
                    />

                    <Can do="update" on='CongregationEvent'>
                        <FormControl sx={{ m: 1, minWidth: 140 }}>
                            <InputLabel>{t("who_has_access")}</InputLabel>
                            <Select
                                value={values.viewer}
                                label={t("who_has_access")}
                                onChange={(event: SelectChangeEvent) => {
                                    formik.setFieldValue('viewer', event.target.value as string);
                                }}
                            >
                                <MenuItem value="all">{t("all")}</MenuItem>
                                <MenuItem value="elders">{t("elders")}</MenuItem>
                                <MenuItem value="appointed">{t("appointed")}</MenuItem>
                                <MenuItem value="owner">{t("owner")}</MenuItem>
                            </Select>
                        </FormControl>
                        <Autocomplete
                            disablePortal
                            id="publisher"
                            options={props.publishers ? props.publishers : []}
                            getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.lastName} ${publisher.firstName}` : ''}
                            value={formik.values.publisher}
                            renderInput={(params) => <TextField {...params} margin="normal" label={t("publisher")} />}
                            onChange={(e, publisher) => {
                                formik.setFieldValue("publisher", publisher)
                                formik.setFieldValue("publisherId", publisher?.id)
                                formik.setFieldValue('viewer', "owner");
                            }}
                        />


                        <br /><br /><br />

                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                            <MobileDateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label={t("from")}
                                openTo="hours"
                                minDate={new Date()}
                                value={formik.values.from}
                                onChange={value => {
                                    formik.setFieldValue('from', value);
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                            <MobileDateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label={t("to")}
                                openTo="hours"
                                minDate={new Date()}
                                value={values.to}
                                onChange={value => {
                                    formik.setFieldValue('to', value);
                                }}
                            />
                        </LocalizationProvider>

                        <br /><br />

                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                            <DatePicker
                                renderInput={(props) => <TextField {...props} />}
                                label={t("notification")}
                                minDate={new Date()}
                                value={formik.values.notification}
                                onChange={value => {
                                    formik.setFieldValue('notification', value);
                                }}
                            />
                        </LocalizationProvider>

                    </Can>

                </DialogContent>
                <DialogActions>
                    <Can do="delete" on='CongregationEvent'>
                        {props.event && <Button color="error" onClick={() => props.onDelete!(props.event!)}>{t("delete")}</Button>}
                    </Can>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Can do="update" on='CongregationEvent'>
                        <Button type='submit'>{t("submit")}</Button>
                    </Can>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default withLazyMounting(CongregationEventDialog);