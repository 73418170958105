import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { withLazyMounting } from '../../../components/hoc/LazyDialog';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useEffect, useState } from 'react';
import { useStores } from '../../../stores';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// pdfjs.GlobalWorkerOptions.workerSrc = './js/pdf.worker.min.mjs';

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

interface Props {
    isOpen: boolean;
    fileName: string;
    onClose: () => void;
}

const ShowFileDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { filesStore } = useStores();
    const [fileType, setFileType] = useState<string>();
    const [file, setFile] = useState<any>();
    const [numPages, setNumPages] = useState<number>();

    useEffect(() => {
        filesStore.downloadFileBlob(props.fileName, (blob, type) => {
            setFileType(type);
            if (type !== "application/pdf") {
                const url = URL.createObjectURL(blob)
                setFile(url);
            } else {
                setFile(blob);
            }
        });
    }, []);

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
        setNumPages(nextNumPages);
    }

    return (
        <Dialog fullWidth maxWidth="md" open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{`${t("file")} - ${props.fileName}`}</DialogTitle>
            <DialogContent>
                {fileType === 'application/pdf' ? (
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                        {Array.from(new Array(numPages), (_el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={800}
                            />
                        ))}
                    </Document>
                ) : (
                    <img src={file} alt="Downloaded" style={{ maxWidth: '100%', marginTop: '20px' }} />
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
});

export default withLazyMounting(ShowFileDialog);