import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import LoadingDimmer from '../../../components/LoadingDimmer';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const DEFAULT_ROOM_COUNT = 2;

const ScheduleEpubImportDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { scheduleStore, serverStore } = useStores();
    const [count, setCount] = React.useState(DEFAULT_ROOM_COUNT);
    const fileInput = React.useRef<HTMLInputElement>(null);

    const uploadEpub = (event: any) => {
        scheduleStore.uploadEpub(event.target.files, count, () => {
            props.onClose();
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value;
        if (value > 0 && value < 4) {
            setCount(value);
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("import_epub")}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    id="count"
                    label={t("congregation_class_count")}
                    type="number"
                    fullWidth
                    variant="standard"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={count || ''}
                    onChange={handleChange}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("cancel")}</Button>
                <Button
                    color="inherit"
                    type="button"
                    onClick={() => fileInput.current && fileInput.current.click()}>
                    {t("submit")}
                    <input ref={fileInput} type="file" hidden onChange={uploadEpub} />
                </Button>
            </DialogActions>
            <LoadingDimmer enabled={serverStore.serverRequestLoading} />
        </Dialog>
    );
});

export default withLazyMounting(ScheduleEpubImportDialog);