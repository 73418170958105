import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useOpenDialog } from '../../hooks/useOpenDialog';
import Can from '../../components/Can';
import { KnowledgeTopic } from '../../domain/KnowledgeTopic';
import { useConfirm } from 'material-ui-confirm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { KnowledgeNote } from '../../domain/KnowledgeNote';
import KnowledgeNoteDialog from './dialogs/KnowledgeNoteDialog';
import DefaultTextArea from '../../components/TextArea';
import KnowledgeTopicDialog from './dialogs/KnowledgeTopicDialog';

export default observer(() => {
    const { t } = useTranslation();
    const { knowledgeStore } = useStores();
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedTopic, setSelectedTopic] = React.useState<KnowledgeTopic | undefined>();
    const [selectedNote, setSelectedNote] = React.useState<KnowledgeNote | undefined>();

    React.useEffect(() => {
        knowledgeStore.getKnowledgeTopics();
    }, []);

    const deleteTopic = (id: number) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            closeDialog();
            setSelectedTopic(undefined);
            knowledgeStore.deleteKnowledgeTopic(id);
        });
    }

    const deleteNote = (id: number) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            knowledgeStore.deleteKnowledgeNote(id);
        });
    }

    const openUpdateTopicDialog = (topic: KnowledgeTopic) => {
        setSelectedTopic(topic);
        openDialog("manage-knowledge-topic")
    }

    const openCreateNoteDialog = (topic: KnowledgeTopic) => {
        setSelectedTopic(topic);
        openDialog("manage-knowledge-note")
    }

    const openUpdateNoteDialog = (note: KnowledgeNote) => {
        setSelectedNote(note);
        openDialog("manage-knowledge-note")
    }

    const closeNoteDialog = () => {
        setSelectedNote(undefined);
        setSelectedTopic(undefined);
        closeDialog();
    }

    return (
        <>
            <Container maxWidth="xl">
                <Grid container>
                    {knowledgeStore.knowledgeTopics && knowledgeStore.knowledgeTopics.map(topic => (
                        <Grid key={topic.name} item xs={4} minWidth={300} padding={1}>
                            <Paper sx={{ p: 2 }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                    <h2>{topic.name}</h2>
                                </div>
                                {topic.notes.map(note => (
                                    <Accordion key={note.id} >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                            <Typography sx={{ width: '33%', flexShrink: 0 }} >{note.name}</Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>{note.description}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DefaultTextArea
                                                disabled={true}
                                                value={note.text}
                                            />
                                            <br />

                                        </AccordionDetails>
                                        <AccordionActions>
                                            <Can do="manage" on='Knowledge'>
                                                <IconButton onClick={() => openUpdateNoteDialog(note)}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                                <IconButton onClick={() => deleteNote(note.id)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </Can>
                                        </AccordionActions>
                                    </Accordion>
                                ))}
                                <br />
                                <Can do="manage" on='Knowledge'>
                                    <IconButton onClick={() => openCreateNoteDialog(topic)}>
                                        <AddIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => openUpdateTopicDialog(topic)}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => deleteTopic(topic.id)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </Can>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <KnowledgeNoteDialog
                isOpen={isOpen("manage-knowledge-note")}
                onClose={closeNoteDialog}
                topic={selectedTopic}
                note={selectedNote}
            />

            <KnowledgeTopicDialog
                isOpen={isOpen("manage-knowledge-topic")}
                onDelete={deleteTopic}
                onClose={closeNoteDialog}
                topic={selectedTopic}
            />
        </ >
    );
});