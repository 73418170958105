import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from "react-i18next";
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    publisher: Publisher;
    onSubmit: (publisher: Publisher) => void;
    onClose: () => void;
}

const RestoreDeletedPublisherDialog = (props: Props) => {
    const { t } = useTranslation();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: props.publisher,
        onSubmit: (publisher) => {
            props.onSubmit(publisher)
            props.onClose();
        }
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("are_you_sure")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                <TextField
                        margin="normal"
                        id="firstName"
                        label={t("name")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={values.firstName || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="lastName"
                        label={t("surname")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={values.lastName || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="notes"
                        label={t("notes")}
                        type="text"
                        fullWidth
                        disabled={true}
                        variant="standard"
                        value={values.notes || ''}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit' color='success'>{t("restore")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default withLazyMounting(RestoreDeletedPublisherDialog);