import * as React from 'react';
import Panel from '../panel/Panel';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { Button, Grid } from '@mui/material';
import { IconButton } from '@mui/material';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import CartWitnessingPlaceDialog from './dialogs/CartWitnessingPlaceDialog';
import { CartWitnessingPlace } from '../../domain/CartWitnessingPlace';
import { useStores } from '../../stores';
import { useConfirm } from 'material-ui-confirm';
import CartWitnessingContent from './CartWitnessingContent';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


export default observer(() => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const { cartWitnessingStore } = useStores();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const [place, setPlace] = React.useState<CartWitnessingPlace | undefined>();

    const createCartPlace = (cartPlace: CartWitnessingPlace) => {
        cartWitnessingStore.createPlace(cartPlace, () => {
            closeDialog();
        })
    }

    const updateCartPlace = (cartPlace: CartWitnessingPlace) => {
        cartWitnessingStore.updatePlace(place!.id, cartPlace, () => {
            closeDialog();
        })
    }

    const deleteCartPlace = () => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")} ${place!.name}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            cartWitnessingStore.deletePlace(place!.id);
        });
    }

    const headerItems = [];

    if (!isMobile) {
        headerItems.push(
            <Can key="new_cart_place_btn" do="create" on='CartWitnessingPlace'>
            <Button
                type="button"
                color="inherit"
                onClick={() => openDialog("new-cart-place")}>
                {t("add_new_cart_witnessing_place")}
            </Button>
        </Can>
        );
    }

    if (place) {
        headerItems.push(
            <Can key="update_cart_place_btn" do="update" on='CartWitnessingPlace'>
                <Button
                    type="button"
                    color="inherit"
                    onClick={() => openDialog("update-cart-place")}>
                    {t("update_place")}
                </Button>
            </Can>
        );
        if (!isMobile) {
            headerItems.push(
                <Can key="delete_cart_place_btn" do="delete" on='CartWitnessingPlace'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={deleteCartPlace}>
                        {t("delete_place")}
                    </Button>
                </Can>
            );
        }
    }

    return (
        <Panel
            title={t("carts")}
            headerItems={headerItems}>

            <CartWitnessingContent
                onPlaceChange={setPlace}
                extraToolbar={(
                    <Can do="manage" on='CartWitnessingPlace'>
                        <Grid container>
                            <Grid item xs={11}></Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => navigate('analysis')}>
                                    <SsidChartIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Can>
                )}
            />

            <CartWitnessingPlaceDialog
                isOpen={isOpen("new-cart-place")}
                onSubmit={createCartPlace}
                onClose={closeDialog}
            />
            <CartWitnessingPlaceDialog
                isOpen={isOpen("update-cart-place")}
                cartPlace={place}
                onSubmit={updateCartPlace}
                onClose={closeDialog}
            />
        </Panel >
    );
});