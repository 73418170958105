import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Territory } from '../../../domain/Territory';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';

interface Props {
    publisher: Publisher;
    onAssignmentEdit: (assignment: TerritoryAssignment) => void;
    onTerritoryReturn: (territory: Territory) => void;
    onShowTerritory: (territory: Territory) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("group")}</TableCell>
                        <TableCell>{t("territory")}</TableCell>
                        <TableCell>{t("notes")}</TableCell>
                        <TableCell>{t("taken_at")}</TableCell>
                        <TableCell>{t("days_gone")}</TableCell>
                        <TableCell>{t("actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.publisher.territoryAssignments!.map(assign => {
                        return <TableRow key={assign.id}>
                            <TableCell>
                                {assign.territory!.territoryGroup!.name}
                            </TableCell>
                            <TableCell>
                                <b>{assign.territory!.title}</b>
                            </TableCell>
                            <TableCell>
                                {assign.notes}
                            </TableCell>
                            <TableCell>
                                {moment(assign.takenAt).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell>
                                <b>{moment().diff(moment(assign.takenAt), 'days')}</b>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => props.onShowTerritory(assign.territory!)}>
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={() => props.onAssignmentEdit(assign)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => props.onTerritoryReturn(assign.territory!)}>
                                    <KeyboardReturnIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};