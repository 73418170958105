import * as React from 'react';
import { useStores } from '../../../stores';
import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    publisherId: number;
    onClose: () => void;
}

const PublisherTerritoriesDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();
    const [assignments, setAssignments] = React.useState<TerritoryAssignment[]>([]);

    React.useEffect(() => {
        if (props.isOpen) {
            territoryStore.getPublisherAssignments(props.publisherId, true, (a: TerritoryAssignment[]) => {
                setAssignments(a);
            });
        }
    }, [props.publisherId, props.isOpen]);

    const calculateDaysGone = (assignment: TerritoryAssignment): number => {
        const from = assignment.returnedAt ? moment(assignment.returnedAt) : moment();
        return from.diff(moment(assignment.takenAt), 'days')
    }

    return (
        <Dialog maxWidth="lg" open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("territories")}</DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("group")}</TableCell>
                                <TableCell>{t("territory")}</TableCell>
                                <TableCell>{t("taken_at")}</TableCell>
                                <TableCell>{t("returned_at")}</TableCell>
                                <TableCell>{t("days_gone")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignments.map((assign: TerritoryAssignment) => (
                                <TableRow key={assign.id}>
                                    <TableCell>{assign.territory!.territoryGroup!.name}</TableCell>
                                    <TableCell><b>{assign.territory!.title}</b></TableCell>
                                    <TableCell>{moment(assign.takenAt).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{assign.returnedAt && moment(assign.returnedAt).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell><b>{calculateDaysGone(assign)}</b></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
});

export default withLazyMounting(PublisherTerritoriesDialog);