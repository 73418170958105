import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

interface FormValues {
    id: number;
    name: string;
    overseer: Publisher;
}

const GroupCreateDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();

    const schema = yup.object().shape({
        name: yup.string(),
        overseer: yup.object().required(t("field_is_required", { name: t("overseer") })),
    });
    const initialValues = {} as FormValues;
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            publisherStore.createServiceGroup(values.name, values.overseer.id)
            props.onClose();
        },
    });

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} fullWidth={true} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("create_new_group")}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        id="name"
                        label={t("name")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name && errors.name as string}
                        value={values.name || ''}
                        onChange={formik.handleChange}
                    />
                    <Autocomplete
                        disablePortal={false}
                        id="overseer"
                        options={publisherStore.publishers.filter(publisher => publisher.appointments.map(app => app.name).includes("Elder"))}
                        getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.firstName} ${publisher.lastName}` : ''}
                        value={values.overseer || {} as Publisher}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={!!errors.overseer}
                                helperText={errors.overseer && errors.overseer as string}
                                margin="normal"
                                label={t("overseer")}
                            />}
                        onChange={(e, overseer) => {
                            formik.setFieldValue("overseer", overseer)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(GroupCreateDialog);