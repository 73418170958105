import Paper from '@mui/material/Paper';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { HomeImageButton } from './HomeImageButton';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

interface Props {
    selected: number;
    alreadySubmittedReport: boolean;
    onClick: (index: number) => void;
    onSubmitReportClick: () => void;
}

export const HomeTabs = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Paper
            elevation={3}
            sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                textAlign: "center",
            }}
        >

            <MobileView>
                {props.alreadySubmittedReport ? (
                    <ToggleButtonGroup
                        size='small'
                        exclusive
                        value={props.selected}
                    >
                        <ToggleButton value={0} onClick={() => props.onClick(0)}>
                            {t("carts")}
                        </ToggleButton>
                        <ToggleButton value={1} onClick={() => props.onClick(1)}>
                            {t("territories")}
                        </ToggleButton>
                        <ToggleButton value={2} onClick={() => props.onClick(2)}>
                            {t("schedule_short")}
                        </ToggleButton>
                    </ToggleButtonGroup>
                ) : (
                    <>
                        <Typography variant="h6" gutterBottom component="div">
                            {t("please_submit_your_report")}
                        </Typography>
                        <Button variant='contained' onClick={() => props.onSubmitReportClick()}>
                            {t("submit_a_report")}
                        </Button>
                    </>
                )}


            </MobileView>
            <BrowserView>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', p: 2, border: '1px dashed grey' }}>
                    {props.alreadySubmittedReport ? (
                        <>
                            <HomeImageButton
                                name="carts"
                                image="url(/cart.jpg)"
                                width='33%'
                                onClick={() => props.onClick(0)}
                            />
                            <HomeImageButton
                                name="territories"
                                image="url(/map.png)"
                                width='33%'
                                onClick={() => props.onClick(1)}
                            />
                            <HomeImageButton
                                name="schedule"
                                image="url(/schedule.png)"
                                width='33%'
                                onClick={() => props.onClick(2)}
                            />
                        </>
                    ) : (
                        <HomeImageButton
                            name="please_submit_your_report"
                            image="url(/cart.jpg)"
                            width='100%'
                            onClick={props.onSubmitReportClick}
                        />
                    )}

                </Box>
            </BrowserView>
        </Paper>
    );
}