import * as React from 'react';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { ActivityRecord } from '../../domain/ActivityRecord';
import ActivityInfoTab from './ActivityInfoTab';
import ActivityErrorTab from './ActivityErrorTab';

export default observer(() => {
    const { t } = useTranslation();
    const { activityStore } = useStores();
    const [records, setRecords] = React.useState<ActivityRecord[]>([]);
    const [tab, setTab] = React.useState(0);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    React.useEffect(() => {
        activityStore.getAll((newRecords) => {
            setRecords(newRecords);
        });
    }, []);

    return (
        <Panel
            title={t("activity")}
            headerItems={[]}>
            <Tabs value={tab} onChange={handleChange}>
                <Tab key="info" label={t("info")} />,
                <Tab key="errors" label={t("errors")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <ActivityInfoTab records={records.filter(record => record.level === "info")} />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <ActivityErrorTab records={records.filter(record => record.level === "error")} />
            </div>
        </Panel >
    );
});