import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';

export class FilesStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    files: any[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getFileList = () => {
        this.serverStore.get('/files/list', action((data) => {
            this.files = data.files;
        }));
    }

    uploadFile = (file: any) => {
        this.serverStore.upload(`/files/upload`, file, action(() => {
            this.rootStore.notificationsStore.add('file_is_uploaded', "success");
            this.getFileList();
        }));
    }

    downloadFileBlob = (name: string, callback: (file: any, type: string) => void) => {
        this.serverStore.downloadBlob(`/files/download/${name}`, action((blob, type) => {
            callback(blob, type);
        }));
    }

    downloadFile = (name: string) => {
        this.serverStore.downloadGet(`/files/download/${name}`, name)
    }

    renameFile = (values: { oldName: string, newName: string }) => {
        this.serverStore.put('/files/rename', values, () => {
            this.getFileList();
        });
    }

    deleteFile = (name: string) => {
        this.serverStore.delete(`/files/delete/${name}`, () => {
            this.rootStore.notificationsStore.add('file_is_deleted', "success");
            this.getFileList();
        });
    }
}