import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { getLocale } from '../../../utils/localeUtils';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const ScheduleDownloadExcelDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { scheduleStore } = useStores();

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            scheduleType: "workday"
        },
        onSubmit: (values) => {
            scheduleStore.downloadExcel(values);
            props.onClose();
        },
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_excel")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            views={['year', 'month']}
                            value={values.date}
                            onChange={value => {
                                formik.setFieldValue('date', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="date" />}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(ScheduleDownloadExcelDialog);