import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSearchSubmit: (value: string) => void;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState("");

    const handleSubmit = () => {
        props.onSearchSubmit(value);
        props.onClose();
    }

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("search")}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("cancel")}</Button>
                <Button color="primary" onClick={handleSubmit}>{t("submit")}</Button>
            </DialogActions>
        </Dialog >
    );
};